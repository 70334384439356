import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Seo } from "../components/";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Links" keywords={[`podcasts`, `reads`, `talks`]} mdxType="Seo" />
    <h1 {...{
      "id": "links"
    }}>{`Links`}</h1>
    <p>{`Stuff I read and listen to (that are technical or tech-adjacent).`}</p>
    <h2 {...{
      "id": "podcasts"
    }}>{`Podcasts`}</h2>
    <p>{`Separating these by (very generally) more topical podcasts vs interview-based.`}</p>
    <h3 {...{
      "id": "topical-format"
    }}>{`Topical format`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://syntax.fm/"
          }}>{`Syntax.fm`}</a></strong>{`: A web development podcast
from `}<a parentName="li" {...{
          "href": "https://twitter.com/wesbos"
        }}>{`Wes Bos`}</a>{` and `}<a parentName="li" {...{
          "href": "https://twitter.com/stolinski"
        }}>{`Scott Tolinski`}</a>{`. Focuses a lot of the JavaScript
ecosystem.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://changelog.com/jsparty"
          }}>{`JS Party`}</a></strong>{`: A `}<a parentName="li" {...{
          "href": "https://changelog.com/"
        }}>{`Changelog`}</a>{` podcast about JavaScript and the web. They recently added some `}<a parentName="li" {...{
          "href": "https://twitter.com/JSPartyFM/status/1118892568375382017"
        }}>{`fresh voices`}</a>{` so I'm excited for that.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://www.codenewbie.org/basecs"
          }}>{`Base.CS`}</a></strong>{`: Beginner-friendly computer science lessons hosted by `}<a parentName="li" {...{
          "href": "https://twitter.com/vaidehijoshi"
        }}>{`Vaidehi Joshi`}</a>{` and `}<a parentName="li" {...{
          "href": "https://twitter.com/saronyitbarek"
        }}>{`@Saron Yitbarek`}</a>{`. Produced by `}<a parentName="li" {...{
          "href": "https://twitter.com/CodeNewbies"
        }}>{`@CodeNewbies`}</a>{`. Based on Vaidehi's awesome `}<a parentName="li" {...{
          "href": "https://medium.com/basecs"
        }}>{`written basecs series`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://frontendhappyhour.com/"
          }}>{`Front End Happy Hour`}</a></strong>{`: A podcast featuring panelists of engineers from (mostly giant companies like Netflix) talking over drinks about all things Front End development.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://gimletmedia.com/shows/reply-all"
          }}>{`Reply All`}</a></strong>{`: This is kind of cheating but it's my favorite podcast, hosted by `}<a parentName="li" {...{
          "href": "https://twitter.com/PJVogt"
        }}>{`PJ Vogt`}</a>{` and `}<a parentName="li" {...{
          "href": "https://twitter.com/AGoldmund"
        }}>{`Alex Goldman`}</a>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "interview-format"
    }}>{`Interview format`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "http://www.strongfeelings.co/podcast/"
          }}>{`Strong Feelings`}</a></strong>{`: A weekly podcast about work, friendship, and feminism from `}<a parentName="li" {...{
          "href": "https://twitter.com/theledu"
        }}>{`Katel LeDu`}</a>{` & `}<a parentName="li" {...{
          "href": "https://twitter.com/sara_ann_marie"
        }}>{`Sara Wachter-Boettcher`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://www.codenewbie.org/podcast"
          }}>{`CodeNewbie`}</a></strong>{`: Stories from people on their coding journey. Hosted by `}<a parentName="li" {...{
          "href": "https://twitter.com/saronyitbarek"
        }}>{`Saron Yitbarek`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://reactpodcast.simplecast.fm/"
          }}>{`The React Podcast`}</a></strong>{`: Conversations around the React ecosystem. Hosted by `}<a parentName="li" {...{
          "href": "https://twitter.com/chantastic"
        }}>{`Chantastic`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://shoptalkshow.com/"
          }}>{`Shop Talk Show`}</a></strong>{`: A live podcast with `}<a parentName="li" {...{
          "href": "https://twitter.com/chriscoyier"
        }}>{`Chris Coyier`}</a>{` and `}<a parentName="li" {...{
          "href": "https://twitter.com/davatron5000"
        }}>{`Dave Rupert`}</a>{` about front end web design, development, and UX.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://undefined.fm/"
          }}>{`The Undefined`}</a></strong>{`: An engineering podcast from `}<a parentName="li" {...{
          "href": "https://twitter.com/JaredPalmer"
        }}>{`Jared Palmer`}</a>{` and `}<a parentName="li" {...{
          "href": "https://twitter.com/Ken_Wheeler"
        }}>{`Ken Wheeler`}</a>{`. Liberal cursing.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://softwareengineeringdaily.com/category/all-episodes/exclusive-content/Podcast/"
          }}>{`Software Engineering Daily`}</a></strong>{`: Technical interviews about software topics. This is all over the map, by design, and tons of content. I tend to pick and choose.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://secondcareerdevs.com/"
          }}>{`Second Career Devs`}</a></strong>{`: Interviews with folks who transitioned into software engineering from a different career. Hosted by `}<a parentName="li" {...{
          "href": "https://twitter.com/kyleshevlin"
        }}>{`Kyle Shevlin`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://a11yrules.com/"
          }}>{`a11y rules`}</a></strong>{`: Conversations with folks centered around web accessibility. Hosted by `}<a parentName="li" {...{
          "href": "https://twitter.com/vavroom"
        }}>{`Nicolas Steenhout`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "articles"
    }}>{`Articles`}</h2>
    <p>{`Want to start keeping track of "hall of fame" articles I think are important, canonical, and want to periodically revisit.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://alistapart.com/article/canary-in-a-coal-mine-how-tech-provides-platforms-for-hate/"
        }}>{`Canary in a Coal Mine: How Tech Provides Platforms for Hate`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/TatianaTMac"
        }}>{`Tatiana Mac`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "talks"
    }}>{`Talks`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=nQq_gZiZ-jg"
        }}>{`How Privilege Defines Performance`}</a>{`, by `}<a parentName="li" {...{
          "href": "https://twitter.com/TatianaTMac"
        }}>{`Tatiana Mac`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://vimeo.com/319388683"
        }}>{`Empathy Reifies Disability Stigmas`}</a>{` by `}<a parentName="li" {...{
          "href": "https://twitter.com/elizejackson"
        }}>{`Liz Jackson`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      